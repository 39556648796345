<template>
  <div class="p-component-overlay" v-if="loading">
    <i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
  </div>
  <div>
    <div v-if="!viewTable" class="grid grid-cols-12 gap-6 mt-5">
      <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          Mostrando {{ paginateService.from }} a {{ paginateService.to }} de {{ paginateService.total }} registros
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
                @blur="searchService"
                type="text"
                class="form-control w-56 box pr-10 placeholder-theme-8"
                placeholder="Buscar..."
            />
            <SearchIcon
                class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <Toast />
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible table-container">
        <table class="table table-report -mt-2 responsive-table">
          <thead>
          <tr>
            <th class="whitespace-nowrap">SOLICITUD</th>
            <th class="whitespace-nowrap">Nº DE PEDIDO</th>
            <th class="text-center whitespace-nowrap">CLIENTE</th>
            <th class="text-center whitespace-nowrap">DOCUMENTO DEL CLIENTE</th>
            <th class="text-center whitespace-nowrap">FECHA</th>
            <th class="text-center whitespace-nowrap">ESTADO</th>
            <th class="text-center whitespace-nowrap">ÚLTIMA NOVEDAD</th>
            <th class="text-center whitespace-nowrap">ACCIONES</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>
              <div class="relative text-gray-700 dark:text-gray-300">
                <input
                    @blur="searchClients"
                    type="text"
                    class="form-control bg-gray-200 box pr-10 placeholder-theme-8"
                    placeholder="Buscar..."
                />
                <SearchIcon
                    class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                />
              </div>
            </td>
            <td></td>
            <td></td>
            <td>
              <div class="relative text-gray-700 dark:text-gray-300">
                <input
                    @blur="searchStatus"
                    type="text"
                    class="form-control bg-gray-200 box pr-10 placeholder-theme-8"
                    placeholder="Buscar..."
                />
                <SearchIcon
                    class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                />
              </div>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr
              v-for="(service, key) in services"
              :key="key"
              class="intro-x"
          >
            <td class="text-center">{{ service.solicitud }}</td>
            <td class="text-center">{{ service.Pedido  }}</td>
            <td class="text-center">{{ service.nombre_cliente  }}</td>
            <td class="text-center">{{ service.DocCliente  }}</td>
            <td class="text-center">{{ service.created_at  }}</td>
            <td class="text-center">{{ service.status  }}</td>
            <td class="text-center">{{ service.carga_pendiente }}</td>
            <td class="table-report__action w-56">
              <div class="flex justify-center items-center">
                <a class="flex items-center mr-2 text-theme-10" href="javascript:;" @click="showTrazabilidad(service)">
                  <EyeIcon class="w-4 h-4 mr-2" />
                </a>
                <a class="flex items-center mr-2" href="javascript:;" @click="showServiceOrder(service)">
                  <EyeIcon class="w-4 h-4 mr-2" />
                </a>
                <!--<a class="flex items-center mr-3 text-theme-10" v-if="service.estado != 1" href="javascript:;" @click="editServiceOrder(service)">
                  <EditIcon class="w-4 h-4 mr-2" />
                </a>-->
                <a class="flex items-center mr-2 text-theme-10" href="javascript:;" @click="editServiceOrder(service)">
                  <EditIcon class="w-4 h-4 mr-2" />
                </a>
                <a class="flex items-center mr-2" href="javascript:;" @click="duplicateServiceOrder(service)">
                  <CopyIcon class="w-4 h-4 mr-2" />
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="javascript:;" @click="getGeneratedOrders(paginateService.first_page_url)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li
              v-for="(link, key) in paginateService.links"
              :key="key"
          >
            <a v-if="key === 0 && link.url !== null" class="pagination__link" href="javascript:;" @click="getGeneratedOrders(link.url)">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
            <a v-if="key === 0 && link.url === null" class="pagination__link" href="javascript:;">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
            <a v-if="key === (paginateService.links.length - 1) && link.url !== null" class="pagination__link" href="javascript:;" @click="getGeneratedOrders(link.url)">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
            <a v-if="key === (paginateService.links.length - 1) && link.url === null" class="pagination__link" href="javascript:;">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
            <a v-if="key !== (paginateService.links.length - 1) && key !== 0 && link.active && link.url !== null" class="pagination__link pagination__link--active" href="javascript:;" @click="getGeneratedOrders(link.url)">{{ link.label }}</a>
            <a v-if="key !== (paginateService.links.length - 1) && key !== 0 && !link.active && link.url !== null" class="pagination__link" href="javascript:;" @click="getGeneratedOrders(link.url)">{{ link.label }}</a>
            <a v-if="key !== (paginateService.links.length - 1) && key !== 0 && !link.active && link.url === null" class="pagination__link" href="javascript:;">{{ link.label }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="getGeneratedOrders(paginateService.last_page_url)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select v-model="pagination" @change="changePagination" class="w-20 form-select box mt-3 sm:mt-0">
          <option v-for="option in paginationOptions" :value="option.id" :key="option.id">
            {{ option.name}}
          </option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5">
        <DataTable v-if="viewTable" :value="services" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Servicios
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Servicios no disponibles.
          </template>
          <template #loading>
            Cargando datos de servicios. Por favor espera.
          </template>

          <Column field="Pedido" header="Pedido" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Orden de servicio</span>
              {{slotProps.data.Pedido}}
            </template>
          </Column>
          <Column field="manifiesto" header="Manifiesto" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Manifiesto</span>
              {{slotProps.data.manifiesto}}
            </template>
          </Column>
          <Column field="tipo_servicio" header="Cliente" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Cliente</span>
              {{slotProps.data.tipo_servicio}}
            </template>
          </Column>
          <Column field="Estado" header="Estado" :sortable="true">
          </Column>
          <Column field="Placa" header="Placa" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Placa</span>
              {{slotProps.data.Placa}}
            </template>
          </Column>
          <Column field="novedad" header="Novedad" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Novedad</span>
              {{ getNovedad(slotProps.data) }}
            </template>
          </Column>
          <Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3 text-theme-10" href="javascript:;" @click="showTrazabilidad(slotProps.data)">
                  <EyeIcon class="w-6 h-6 mr-4" />
                </a>
                <a class="flex items-center mr-3" href="javascript:;" @click="showServiceOrder(slotProps.data)">
                  <EyeIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="intro-y box p-5 mt-5" v-if="viewMap">
        <Fieldset legend="Tracking" :toggleable="true">
          <GoogleMap
              api-key="AIzaSyCsCu2R_oXOLYHt3xBmEEAsPZck8kDHKE4"
              style="width: 100%; height: 500px"
              :center="center"
              :zoom="15"
          >
            <Marker :key = "key"  v-for="(marker,key) in markers" :options="marker" />
          </GoogleMap>
        </Fieldset>
      </div>
    </div>
  </div>

</template>

<script>
import AcceptedOrder from '../service/AcceptedOrder';
import {API_LOCAL_URL, API_SATRACK_URL} from "@/config";
import axios from "axios";
import {GoogleMap, Marker} from "vue3-google-map";
import {FilterMatchMode} from 'primevue/api';

export default {
  components: { GoogleMap, Marker },
  data() {
    return {
      center: { lat: 4.15, lng: -73.633 },
      markers: [],
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      loading: true,
      services: [],
      paginateService: {},
      url: null,
      pagination: 35,
      paginationOptions: [
        { name: '10', id: 10 },
        { name: '25', id: 25 },
        { name: '35', id: 35 },
        { name: '50', id: 50 }
      ],
      selectedServices: null,
      service: {},
      expandedRows: [],
      viewMap: false,
      viewTable: false
    }
  },
  AcceptedOrder: null,
  created() {
    if(this.$store.state.auth.scopes) {
      const scopes = this.$store.state.auth.scopes.split(",");
      if (scopes.includes('Trafico')){
        this.viewMap = true;
      }
      if (scopes.includes('Trafico') || scopes.includes('Operaciones')){
        this.viewTable = true;
      }
    }
    this.AcceptedOrder = new AcceptedOrder();
  },
  async mounted() {
    var axios1, axios2, axios3;
    if (this.url === null) {
      this.url = API_LOCAL_URL+'encabezado?pagination='+this.pagination;
    }
    axios1 = await new Promise((resolve) => {
      this.AcceptedOrder.getGeneratedOrders(this.url).then(data => {
        resolve(data);
        this.loading = false;
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los datos',
              detail: 'Error al traer los datos',
              life: 5000
            });
            this.loading = false;
          });
    });
    //
    this.paginateService = axios1;
    this.services = axios1.data;
    //viewMap
    if(this.viewMap){
      axios2 = await new Promise((resolve) => {
        for (const i in this.services) {
          for (const j in this.services[i].vehiculo) {

            if (this.services[i].vehiculo[j].GPSoperador === 'SATRACK' || this.services[i].vehiculo[j].GPSoperador === 'satrack') {

              var postData = '<Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">\n' +
                  '    <Body>\n' +
                  '        <getLastEvent xmlns="http://tempuri.org/">\n' +
                  '            <UserName>' + this.services[i].vehiculo[j].GPSUsuario + '</UserName>\n' +
                  '            <Password>' + this.services[i].vehiculo[j].GPSClave + '</Password>\n' +
                  '            <PhysicalID>' + this.services[i].vehiculo[j].Placa + '</PhysicalID>\n' +
                  '        </getLastEvent>\n' +
                  '    </Body>\n' +
                  '</Envelope>';

              this.postApiSatrack('getLastEvent', postData)
                  .then(response => {
                    this.postApiLocal('lastevent', response.data.LastEvents)
                        .catch(() => {
                          this.$toast.add({
                            severity: 'error',
                            summary: 'Error de solicitud',
                            detail: 'No se ha podido procesar la solicitud',
                            life: 5000
                          });
                        });
                    resolve(response);
                  })
                  .catch(() => {
                    this.$toast.add({
                      severity: 'error',
                      summary: 'Error de solicitud',
                      detail: 'No se ha podido procesar la solicitud',
                      life: 5000
                    });
                  });

            }

          }
        }
      });
      axios3 = await new Promise((resolve) => {
        this.AcceptedOrder.getLastEvent().then(data => {
          resolve(data);
        })
            .catch(() => {
              this.$toast.add({
                severity: 'error',
                summary: 'Error al traer los datos',
                detail: 'Error al traer los datos',
                life: 5000
              });
            });
      });
      for (const i in axios3) {
        this.center = { lat: parseFloat(axios3[i].Latitud), lng: parseFloat(axios3[i].Longitud) };
        this.markers.push({ position: { lat: parseFloat(axios3[i].Latitud), lng: parseFloat(axios3[i].Longitud) }, title: axios3[i].Placa });
      }
    }else{
      axios2 = await new Promise((resolve) => {
        resolve(null);
      });
      axios3 = await new Promise((resolve) => {
        resolve(null);
      });
    }

    Promise.all([axios1, axios2, axios3]).then(() => {
      this.$toast.add({severity:'success', summary: 'Exito', detail: 'Datos cargados correctamente.', life: 5000});
    });

  },
  methods: {
    async getGeneratedOrders(url){
      this.url = url;
      this.loading = true;
      var axios1;
      axios1 = await new Promise((resolve) => {
        this.AcceptedOrder.getGeneratedOrders(this.url).then(data => {
          resolve(data);
          this.loading = false;
        })
            .catch(() => {
              this.$toast.add({
                severity: 'error',
                summary: 'Error al traer los datos',
                detail: 'Error al traer los datos',
                life: 5000
              });
              this.loading = false;
            });
      });
      //
      this.paginateService = axios1;
      this.services = axios1.data;
      Promise.all([axios1]).then(() => {
        this.$toast.add({severity:'success', summary: 'Exito', detail: 'Datos cargados correctamente.', life: 5000});
      });
    },
    searchService(e){
      const url = new URL(this.url);
      const nuevosParametros = {
        q: e.target.value
      };

      Object.keys(nuevosParametros).forEach(key => {
        url.searchParams.set(key, nuevosParametros[key]);
      });

      this.getGeneratedOrders(url.toString());
    },
    searchStatus(e){
      const url = new URL(this.url);
      const nuevosParametros = {
        q_status: e.target.value
      };

      Object.keys(nuevosParametros).forEach(key => {
        url.searchParams.set(key, nuevosParametros[key]);
      });

      this.getGeneratedOrders(url.toString());
    },
    searchClients(e){
      const url = new URL(this.url);
      const nuevosParametros = {
        q_client: e.target.value
      };

      Object.keys(nuevosParametros).forEach(key => {
        url.searchParams.set(key, nuevosParametros[key]);
      });

      this.getGeneratedOrders(url.toString());
    },
    changePagination(){
      const url = new URL(this.url);
      const nuevosParametros = {
        pagination: this.pagination
      };

      Object.keys(nuevosParametros).forEach(key => {
        url.searchParams.set(key, nuevosParametros[key]);
      });

      this.getGeneratedOrders(url.toString());
    },
    getRuta(service){
      if (typeof(service.IdRuta) !== "undefined" && service.IdRuta !== null) {
        return service.IdRuta.Ruta;
      }
      return null;
    },
    getNovedad(service){
      if (service.novedad.length > 0){
        return service.novedad[service.novedad.length-1].novedad
      }
      return null;
    },
    showTrazabilidad(service){
      var id = service.solicitud_id;
      var serv = service.id;
      this.$router.push({ name: 'editorder', params: { id, serv } })
    },
    showServiceOrder(service){
      var id = service.id;
      this.$router.push({ name: 'serviceorder', params: { id } })
    },
    editServiceOrder(service){
      var edit = service.id;
      this.$router.push({ name: 'pathorder', params: { edit } })
    },
    duplicateServiceOrder(service){
      var oldid = service.id;
      this.$router.push({ name: 'duplicateorder', params: { oldid } })
    },
    postApiSatrack(method, data){
      const config = {
        headers: {
          "Content-Type": "application/xml",
          "Accept": "application/xml",
        }
      };
      let url = API_SATRACK_URL.replace('{replace}', 'method='+method+'&');
      return axios
          .post(url, data, config);
    },
    postApiLocal(slug, data){
      const config = {
        headers: {
          "Authorization": "Bearer " + this.$store.state.auth.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      return axios
          .post(API_LOCAL_URL+slug, data, config);
    }
  }
}
</script>

<style scoped lang="scss">
.p-component{
  font-size: 0.875rem !important;
}

.p-component-overlay{
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

.table-container {
  overflow-x: auto !important; /* Agrega una barra de desplazamiento horizontal cuando sea necesario */
  max-width: 100% !important; /* Evita que la tabla se extienda más allá de su contenedor */
}

.responsive-table {
  width: 100% !important; /* Hace que la tabla ocupe todo el ancho del contenedor */
  border-collapse: collapse !important; /* Fusiona los bordes de las celdas */
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
